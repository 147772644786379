import React from 'react';
import './App.css';
import './Contact.css';
import './Facilitiesmanagement.css'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import DOMPurify from 'dompurify';
import axios from 'axios';

class Facilitiesmanagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showNameError: false,
            showEmailError: false,
            showPhoneError: false,
            showMessageError: false,
            submittingStatus: "Submit",
            showSuccessfulSubmission: false,
            showContactForm: true
        };
    }

    handleSubmit() {
        const isNameValid = document.getElementById("name").value.length >= 1;
        const isEmailValid = /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/.test(document.getElementById("email").value)
        const isPhoneValid = /^(?=.*\d)[+()0-9\s]{3,17}$/.test(document.getElementById("phoneNumber").value)
        let sanitisedMessage = DOMPurify.sanitize(document.getElementById("messageInput").value);
        const isMessageValid = /^(?:.|\n){0,2004}$/.test(sanitisedMessage);

        this.setState({ showNameError: !isNameValid });
        this.setState({ showEmailError: !isEmailValid });
        this.setState({ showPhoneError: !isPhoneValid });
        this.setState({ showMessageError: !isMessageValid });

        if (
            isNameValid &&
            isEmailValid &&
            isPhoneValid &&
            isMessageValid
        ) {
            let formData = {
                name: document.getElementById("name").value,
                email: document.getElementById("email").value,
                phone: document.getElementById("phoneNumber").value,
                message: sanitisedMessage,
                category: "Facilities Management"
            };
            this.setState({ submittingStatus: "Submitting..." });
            axios.post('https://a44b8ia8nb.execute-api.ap-southeast-2.amazonaws.com/dev/', formData)
                .then(response => {
                    if (response.status === 200) {
                        this.setState({ showContactForm: false })
                        this.setState({ showSuccessfulSubmission: true })
                        setTimeout(function () {
                            window.location.href = '../';
                        }, 4000); // 4000 milliseconds = 4 seconds
                    } else {
                        this.setState({ submittingStatus: "Error, try again" });
                        setTimeout(function () {
                            window.location.href = '../contact';
                        }, 4000); // 4000 milliseconds = 4 seconds
                    }
                });
        }
    }

    contactForm = () => {
        return (
            <div class="contactContainer">
                <div class="contactSection">
                    <span class="contactInputLabel">Name</span>
                    <Form.Control type="fname" id="name" placeholder="Your name" required />
                    {this.state.showNameError ? <span class="contactInputError">Please provide your name</span> : null}
                </div>
                <div class="contactSection">
                    <span class="contactInputLabel">Email</span>
                    <Form.Control type="email" id="email" placeholder="Email address" required />
                    {this.state.showEmailError ? <span class="contactInputError">Please provide your email address</span> : null}
                </div>
                <div class="contactSection">
                    <span class="contactInputLabel">Phone Number</span>
                    <Form.Control type="tel" id="phoneNumber" placeholder="Phone number" required />
                    {this.state.showPhoneError ? <span class="contactInputError">Please provide your phone number</span> : null}
                </div>
                <div class="contactSection">
                    <span class="contactInputLabel">Details</span>
                    <Form.Control as="textarea" type="text" id="messageInput" placeholder="Please provide a brief description of your request" required />
                    {this.state.showMessageError ? <span class="contactInputError">Please keep your message short, under 2000 characters</span> : null}
                </div>
                <div class="contactSection">
                    <Button id="contactSubmit" onClick={() => this.handleSubmit()}>{this.state.submittingStatus}</Button>
                </div>
            </div>
        )
    }

    successfulSubmission = () => {
        return (
            <div class="contactSuccesfulSubmissionContainer">
                <span class="bodyText">Thanks for your interest in our Facilities Management capability, we'll be in touch soon.</span>
            </div>
        )
    }

    render() {
        return (
            <div>
                <div class="contactHeadingContainer">
                    <span class="heading">Facilities Management.</span>
                    <span class="bodyText">Request our commercial and Strata building management services.</span>
                </div>
                <div class="contactFormContainer">
                    {this.state.showContactForm ? this.contactForm() : null}
                    {this.state.showSuccessfulSubmission ? this.successfulSubmission() : null}
                </div>
            </div >
        );
    }
}

export default Facilitiesmanagement;