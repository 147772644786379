import React from 'react';
import { Navbar, Nav, Container, Offcanvas, NavDropdown, Form, FormControl, Button } from 'react-bootstrap';
import icon from './assets/logo.svg';
import './Navigation.css';

class Navigation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <Navbar expand="lg" id="navbar">
                <Container fluid>
                    <Navbar.Brand href="/"><span id="navbarLogo">
                        <img id="navIcon" src={icon} alt="Nav Icon" /></span></Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className="me-auto my-2 my-lg-0"
                            navbarScroll
                        >
                            <Nav.Link href="/"><span class="navLinkText">Home</span></Nav.Link>
                            <Nav.Link href="/services"><span class="navLinkText">Services</span></Nav.Link>
                            <Nav.Link href="/contact"><span class="navLinkText">Contact</span></Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        );
    }
}

export default Navigation;