import React from 'react';
import './App.css';
import './Footer.css';
import icon from './assets/logo.svg';

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div>
                <div class="footerContainer">
                    <div class="footerColumnContainer">
                        <div class="footerColumn" id="footerLogoColumn">
                            <span><img class="footerIcon" src={icon}/></span>
                        </div>
                        <div class="footerColumn">
                            <a class="footerLink" href="/">Home</a>
                            <a class="footerLink" href="/services">Services</a>
                            <a class="footerLink" href="/contact">Contact</a>
                        </div>
                        <div class="footerColumn">
                            <a class="footerLink" href="tel:0405678453">Emergencies: 0405 678 453</a>
                            <a class="footerLink" href="/privacypolicy">Privacy Policy</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;