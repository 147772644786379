import React from 'react';
import './App.css';
import './Services.css';
import { AiFillCheckCircle } from 'react-icons/ai';
import Button from 'react-bootstrap/Button';
import lobby from "./assets/lobby.jpg";
import buff from "./assets/buff.jpg"
import emergency from "./assets/emergency.jpg"


class Services extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    handleButtonClick(location) {
        window.location.href=`services/${location}`;
    }

    render() {
        return (
            <div>
                <div class="pageHeaderContainer">
                    <div class="servicesHeadingContainer"> 
                        <span class="heading">What we offer.</span>
                    </div>
                    
                </div>
                <div class="servicesWideImageContainer">
                    <div class="servicesWideImageWrapper" id="servicesWideImageWrapper1">
                        <img id="servicesLobbyImage" src={lobby}></img>
                    </div>
                </div>

                <div class="servicesFeaturesContainer">
                    <div class="servicesBodyCard">
                        <div class="servicesBodyCardHeadingContainerLeft">
                            <div class="servicesBodyCardHeadingWrapperLeft">
                                <span class="bodyHeading">Facilities Management</span>
                                <span class="bodyText">Comprehensive commercial and Strata building management offerings.</span>
                                <div class="servicesButtonContainer">
                                    <Button id="servicesClearButton" onClick={() => this.handleButtonClick("facilitiesmanagement")}>Request us</Button>
                                </div>
                            </div>
                        </div>
                        <div class="serviceBodyCardItemsContainerRight">
                            <span class="servicesFeatureListItem"><AiFillCheckCircle class="servicesFeatureListIcon" />Building Staffing & Cleaning</span>
                            <span class="servicesFeatureListItem"><AiFillCheckCircle class="servicesFeatureListIcon" />Essential Maintenance Management</span>
                            <span class="servicesFeatureListItem"><AiFillCheckCircle class="servicesFeatureListIcon" />Occupancy Management</span>
                            <span class="servicesFeatureListItem"><AiFillCheckCircle class="servicesFeatureListIcon" />Physical Security & CCTV</span>
                            <span class="servicesFeatureListItem"><AiFillCheckCircle class="servicesFeatureListIcon" />Waste & Car Park Management</span>
                            <span class="servicesFeatureListItem"><AiFillCheckCircle class="servicesFeatureListIcon" />Contractor Management</span>
                        </div>
                    </div>
                </div>

                <div class="servicesWideImageContainer">
                    <div class="servicesWideImageWrapper" id="servicesWideImageWrapper2">
                        <img id="servicesLobbyImage" src={buff}></img>
                    </div>
                </div>

                <div class="servicesFeaturesContainer">
                    <div class="servicesBodyCard slideInFromLeft" id="servicesBodySecondCard">
                        <div class="serviceBodyCardItemsContainerLeft">
                            <span class="servicesFeatureListItem"><AiFillCheckCircle class="servicesFeatureListIcon" />Common Areas</span>
                            <span class="servicesFeatureListItem"><AiFillCheckCircle class="servicesFeatureListIcon" />Windows</span>
                            <span class="servicesFeatureListItem"><AiFillCheckCircle class="servicesFeatureListIcon" />Floors</span>
                            <span class="servicesFeatureListItem"><AiFillCheckCircle class="servicesFeatureListIcon" />Car Parks</span>
                        </div>
                        <div class="servicesBodyCardHeadingContainerRight">
                            <div class="servicesBodyCardHeadingContainer">
                                <span class="bodyHeading">Commercial Cleaning</span>
                                <span class="bodyText">High quality, extensive whole-of-building cleaning services.</span>
                                <div class="servicesButtonContainer">
                                    <Button id="servicesClearButton" onClick={() => this.handleButtonClick("commercialcleaning")}>Request us</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="servicesWideImageContainer">
                    <div class="servicesWideImageWrapper" id="servicesWideImageWrapper3">
                        <img id="servicesLobbyImage" src={emergency}></img>
                    </div>
                </div>

                <div class="servicesFeaturesContainer">
                    <div class="servicesBodyCard slideInFromRight">
                        <div class="servicesBodyCardHeadingContainerLeft">
                            <div class="servicesBodyCardHeadingWrapperLeft">
                                <span class="bodyHeading">Emergency Cleaning</span>
                                <span class="bodyText">Available 24/7 to make-safe and restore, ready on demand.</span>
                                <div class="servicesButtonContainer">
                                    <Button id="servicesClearButton" href="tel:0405678453">Call us</Button>
                                </div>
                            </div>
                        </div>
                        <div class="serviceBodyCardItemsContainerRight">
                            <span class="servicesFeatureListItem"><AiFillCheckCircle class="servicesFeatureListIcon" />Flood Restoration</span>
                            <span class="servicesFeatureListItem"><AiFillCheckCircle class="servicesFeatureListIcon" />Insurance Liaison</span>
                            <span class="servicesFeatureListItem"><AiFillCheckCircle class="servicesFeatureListIcon" />Occupant Communication</span>
                            <span class="servicesFeatureListItem"><AiFillCheckCircle class="servicesFeatureListIcon" />Facility Reinstatement</span>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default Services;