import React from 'react';
import './App.css';
import './Privacypolicy.css'

class Privacypolicy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div>
                <div class="privacyPolicyContainer">
                    <span class="bodyHeading">Privacy Policy</span>
                    <br></br>
                    <span>Eco Pro Green Pty Ltd is committed to providing quality services to you and this policy outlines our ongoing obligations to you in respect of how we manage your Personal Information. We have adopted the Australian Privacy Principles (APPs) contained in the Privacy Act 1988 (Cth) (the Privacy Act). The NPPs govern the way in which we collect, use, disclose, store, secure and dispose of your Personal Information.</span>
                    <br></br><br></br>
                    <span><b>What is Personal Information and why do we collect it?</b></span>
                    <br></br>
                    <span>Personal Information is information or an opinion that identifies an individual. Examples of Personal Information we collect includes names, addresses, email addresses, phone and facsimile numbers.</span>
                    <br></br>
                    <span>This Personal Information is obtained in many ways including correspondence, by telephone and facsimile, by email, via our website www.ecoproservices.com.au, from your website, from media and publications, from other publicly available sources, from cookies, and from third parties. We don’t guarantee website links or policy of authorised third parties.</span>
                    <br></br>
                    <span>We collect your Personal Information for the primary purpose of providing our services to you, providing information to our clients and marketing. We may also use your Personal Information for secondary purposes closely related to the primary purpose, in circumstances where you would reasonably expect such use or disclosure. You may unsubscribe from our mailing/marketing lists at any time by contacting us in writing.</span>
                    <br></br>
                    <span>When we collect Personal Information we will, where appropriate and where possible, explain to you why we are collecting the information and how we plan to use it.</span>
                    <br></br><br></br>
                    <span><b>Sensitive Information</b></span>
                    <br></br>
                    <span>Sensitive information is defined in the Privacy Act to include information or opinion about such things as an individual's racial or ethnic origin, political opinions, membership of a political association, religious or philosophical beliefs, membership of a trade union or other professional body, criminal record or health information.</span>
                    <br></br>
                    <span>Sensitive information will be used by us only:</span>
                    <br></br>
                    <span>
                        <ul>
                            <li>For the primary purpose for which it was obtained</li>
                            <li>For a secondary purpose that is directly related to the primary purpose</li>
                            <li>With your consent; or where required or authorised by law.</li>
                        </ul>
                    </span>
                    <span><b>Third Parties</b></span>
                    <br></br>
                    <span>Where reasonable and practicable to do so, we will collect your Personal Information only from you. However, in some circumstances we may be provided with information by third parties. In such a case we will take reasonable steps to ensure that you are made aware of the information provided to us by the third party.</span>
                    <br></br><br></br>
                    <span><b>Disclosure of Personal Information</b></span>
                    <br></br>
                    <span>Your Personal Information may be disclosed in a number of circumstances including the following:</span>
                    <br></br>
                    <span>
                        <ul>
                            <li>Third parties where you consent to the use or disclosure; and</li>
                            <li>Where required or authorised by law.</li>
                        </ul>
                    </span>
                    <span><b>Security of Personal Information</b></span>
                    <br></br>
                    <span>Your Personal Information is stored in a manner that reasonably protects it from misuse and loss and from unauthorised access, modification or disclosure.</span>
                    <br></br>
                    <span>When your Personal Information is no longer needed for the purpose for which it was obtained, we will take reasonable steps to destroy or permanently de-identify your Personal Information.</span>
                    <br></br><br></br>
                    <span><b>Policy Updates</b></span>
                    <br></br>
                    <span>This Policy may change from time to time and is available on our website.</span>
                    <br></br>
                    <br></br>
                    <span><b>Privacy Policy Complaints and Enquiries</b></span>
                    <br></br>
                    <span>If you have any queries or complaints about our Privacy Policy please contact us at: admin@ecoproservices.com.au</span>
                    <br></br>
                </div>
            </div >
        );
    }
}

export default Privacypolicy;