import './App.css';
import Home from './Home';
import Services from './Services';
import Facilitiesmanagement from './Facilitiesmanagement';
import Commercialcleaning from './Commercialcleaning';
import Contact from './Contact';
import Privacypolicy from './Privacypolicy';
import Navigation from './Navigation';
import Footer from './Footer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <Router>
      <Navigation />
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/*" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services/facilitiesmanagement" element={<Facilitiesmanagement />} />
          <Route path="/services/commercialcleaning" element={<Commercialcleaning />} />
          <Route path="/privacypolicy" element={<Privacypolicy />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;