import React from 'react';
import './App.css';
import './Home.css';
import './Slide.css';
import Button from 'react-bootstrap/Button';
import {AiFillStar} from 'react-icons/ai';
import map from "./assets/map.svg"
import facman from "./assets/facilitiesmangement.jpg"
import cq from "./assets/cq.jpg"

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    handleButtonClick(location) {
        window.location.href=location;
    }

    render() {
        return (
            <div>
                <div class="pageHeaderContainer">
                    <div class="homeHeroContainer">
                        <div id="homeHeroChild">
                            <span class="heading" id="homeHeroHeadingText">Transform your space.</span>
                            <div id="homeHeroTagline">
                                <span class="bodyText">Eco Pro Services, where expert commercial cleaning, sustainability and comprehensive facilities management meet.</span>
                            </div>
                            <div id="homeHeroChildButtonContainer">
                                <Button id="homeHeroButton" onClick={() => this.handleButtonClick("../services")}>See Our Services</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="homeBodyContainer">
                <div class="bodyCard">
                    <div class="bodyCardTextContainer">
                        <span class="bodyHeading">Made in Melbourne. For Melbourne.</span>
                        <span class="bodyText">Established in 2016 in the heart of the CBD, Eco Pro Services is now servicing the Greater Melbourne Area.</span>
                    </div>
                    <div class="bodyCardImageContainer">
                        <img src={map} class="cardImage" id="melImage"/>
                    </div>
                </div>

                <div class="homeClearCard slideInFromLeft">
                    <div class="homeClearImageContainerLeft">
                    <img src={cq} class="homeClearImage"/>
                    </div>
                    <div class="homeClearTextContainerRight">
                        <span class="homeClearHeading">Medical grade commercial cleaning</span>
                        <span class="homeClearText">We offer industry-leading, high-quality cleaning services.</span>
                        <div class="homeBodyButtonContainer">
                            <Button id="bodyClearButton" onClick={() => this.handleButtonClick("services")}>See More</Button>
                        </div>
                    </div>
                </div>

                <div class="homeClearCard slideInFromRight" id="homeBodySecondCard">
                    <div class="homeClearTextContainerLeft">
                        <span class="homeClearHeading">Facilities Management excellence</span>
                        <span class="homeClearText">A trusted partner in the management and maintenance of commercial and Strata buildings across Melbourne.</span>
                        <div class="homeBodyButtonContainer">
                            <Button id="bodyClearButton" onClick={() => this.handleButtonClick("services")}>See More</Button>
                        </div>
                    </div>
                    <div class="homeClearImageContainerRight">
                        <img src={facman} class="homeClearImage" id="cqImage"/>
                    </div>
                </div>
                </div>

                <div id="homeTestimonialsContainer" class="slideInFromBottom">
                    <span class="bodyHeading homeTestimonialHeader">Testimonials</span>
                    <span class="bodyText homeTestimonialBodyText">People love what we do and we want to let you know.</span>
                    <div id="homeTestimonialCardContainer">
                        <div class="homeTestimonialCard">
                            <div class="homeTestimonialCardTextContainer">
                                <span class="homeTestimonialText">"Exceptional general cleaning! Eco Pro Services is the best in the business. Highly recommended."</span>
                                <div class="testimonalStarContainer">
                                    <span class="testimonalStarSpan"><AiFillStar class="testimonialStar"/><AiFillStar class="testimonialStar"/><AiFillStar class="testimonialStar"/><AiFillStar class="testimonialStar"/><AiFillStar class="testimonialStar"/></span>
                                </div>
                            </div>
                        </div>
                        <div class="homeTestimonialCard">
                            <div class="homeTestimonialCardTextContainer">
                                <span class="homeTestimonialText">"Impeccable facility management. Eco Pro Services exceededs our expectations."</span>
                                <div class="testimonalStarContainer">
                                    <span class="testimonalStarSpan"><AiFillStar class="testimonialStar"/><AiFillStar class="testimonialStar"/><AiFillStar class="testimonialStar"/><AiFillStar class="testimonialStar"/><AiFillStar class="testimonialStar"/></span>
                                </div>
                            </div>
                        </div>
                        <div class="homeTestimonialCard">
                            <div class="homeTestimonialCardTextContainer">   
                                <span class="homeTestimonialText">"A reliable and efficient cleaning company. Eco Pro Services is a game-changer."</span>
                                <div class="testimonalStarContainer">
                                    <span class="testimonalStarSpan"><AiFillStar class="testimonialStar"/><AiFillStar class="testimonialStar"/><AiFillStar class="testimonialStar"/><AiFillStar class="testimonialStar"/><AiFillStar class="testimonialStar"/></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default Home;